import { authUrl, clientId } from 'src/configs/authConst'

export const APP_NAME = 'Control Panel'

export const USER_ID = 'http://debttrader.com/claims/user_id'
export const TENANT_TYPE = 'http://debttrader.com/claims/tenant_type'
export const DISPLAY_NAME = 'http://debttrader.com/claims/display_name'
export const PERMISSION_INFO = 'http://debttrader.com/claims/permission_info'
export const BUSINESS_INFO = 'http://debttrader.com/claims/business_info'
export const IS_RESELLER = 'http://debttrader.com/claims/is_reseller'
export const CLIENT_INFO = 'http://debttrader.com/claims/client_info'
export const PERMISSION_INFO_000 =
  'http://debttrader.com/claims/permission_info/000'
export const PERMISSION_INFO_001 =
  'http://debttrader.com/claims/permission_info/001'
export const PERMISSION_INFO_002 =
  'http://debttrader.com/claims/permission_info/002'
export const EMAIL = 'http://debttrader.com/claims/user_email'

export enum PermissionCodeAccessForApps {
  MARKET_PLACE = '000',
  CONTROL_PANEL = '001',
  COMPLIANCE_MANAGEMENT_SYSTEM = '002',
}

export enum PermissionCodeAccess {
  CONTROL_PANEL = '001',
  MANAGE_EXTERNAL_PERMISSION = '001.000.000',
  MANAGE_INTERNAL_PERMISSION = '001.000.001',
  LOG_PERMISSION = '001.001',
  SYSTEM_LOG_PERMISSION = '001.001.000',
  EMAIL_LOG_PERMISSION = '001.001.001',
  MARKETPLACE_LOG_PERMISSION = '001.001.002',
  CONTROLPANEL_LOG_PERMISSION = '001.001.003',
  CMS_LOG_PERMISSION = '001.001.004',
  LOGGING_LOG_PERMISSION = '001.001.005',
  CLIENT_PERMISSION = '001.002',
  CLIENT_BASICINFO_PERMISSION = '001.002.000',
  CLIENT_STATUS_PERMISSION = '001.002.001',
  CLIENT_ASSOCIATION_PERMISSION = '001.002.002',
  CLIENT_INTERNAL_USER_PERMISSION = '001.002.003',
  CLIENT_EXTERNAL_USER_PERMISSION = '001.002.004',

  CLIENT_BUSINESS_COMPLIANCE_PERMISSION = '001.002.005',
  CLIENT_BUSINESS_FEES_PERMISSION = '001.002.006',
  CLIENT_BUSINESS_INSURANCE_PERMISSION = '001.002.007',
  CLIENT_BUSINESS_SCORING_PERMISSION = '001.002.008',

  CLIENT_MILITARY_SCRUB_PERMISSION = '001.002.009',
  CLIENT_BUYER_ACCESS_PERMISSION = '001.002.010',
  CLIENT_BUSINESS_NOTIFICATION_PERMISSION = '001.002.011',

  COMMISSION = '001.003',
  MANAGE_COMMISSION = '001.003.000',
  VIEW_COMMISSION = '001.003.001',

  PORTFOLIO_DATA_MANAGER = '001.004',
  PORTFOLIO_DATA_MANAGER_MANAGE_VALIDATION = '001.004.000',
  PORTFOLIO_DATA_MANAGER_MANAGE_UNMASKED_DATA = '001.004.001',
}

export const INTERNAL = 'internal'
export const EXTERNAL = 'external'
export const BUYER = 'Buyer'
export const SELLER = 'Seller'

export const USER_LOCAL = `dt.user:${authUrl}:${clientId}`

export const WEEKDAYS = [
  { day: 'Monday', value: 1 },
  { day: 'Tuesday', value: 2 },
  { day: 'Wednesday', value: 3 },
  { day: 'Thursday', value: 4 },
  { day: 'Friday', value: 5 },
  { day: 'Saturday', value: 6 },
  { day: 'Sunday', value: 7 },
]

export const AgencyType = [
  { key: 'First Party', value: 1 },
  { key: 'Third Party', value: 2 },
]
